import Grid from "@mui/material/Grid";
import React from "react";

import CodeReviewIcon from "../assets/images/code-audit.png";
import DevSecOpsIcon from "../assets/images/dev-sec-ops.png";
import PipelineIcon from "../assets/images/pipeline.png";
import SoftwareDevelopmentIcon from "../assets/images/software-development.png";
import ServiceCard from "./ServiceCard";

export type Props = {
    /**
     * True if the card descriptions should be shown
     */
    showDescriptions: boolean;

    /**
     * The height of the images in the cards
     */
    cardMediaHeight: number;
}

export default function ServiceCardRow(props: Props) {
    const { cardMediaHeight, showDescriptions } = props;

    const devSecOpsDescription: string = showDescriptions ?
        "We provide DevSecOps automation and techniques which enable rapid development/test/delivey cycles that include continuous security feedback." :
        "";

    const pipelineDescription: string = showDescriptions ?
        "We help you enhance productivity and maximize quality through automated build/test cycles and streamlined deployments with CI/CD pipelines." :
        "";

    const developmentDescription: string = showDescriptions ?
        "We provide elite software development experience in a variety of languages, including web, server-side, embedded, and OS-specific technologies." :
        "";

    const auditDescription: string = showDescriptions ?
        "We provide software quality auditing, including code reviews, design reviews, software security assessments, and performance benchmarking." :
        "";

    return (
        <Grid
            container
            spacing={ 8 }
            direction="row"
            justifyContent="center"
            sx={ {
                pb: 5
            } }
        >
            <ServiceCard
                description={ devSecOpsDescription }
                image={ DevSecOpsIcon }
                title="DevSecOps"
                hrefLink="#services-dev-sec-ops"
                mediaHeight={ cardMediaHeight }
            />
            <ServiceCard
                description={ pipelineDescription }
                image={ PipelineIcon }
                title="CI/CD Pipelines"
                hrefLink="#services-ci-cd-pipelines"
                mediaHeight={ cardMediaHeight }
            />
            <ServiceCard
                description={ developmentDescription }
                image={ SoftwareDevelopmentIcon }
                title="Software Development"
                hrefLink="#services-software-development"
                mediaHeight={ cardMediaHeight }
            />
            <ServiceCard
                description={ auditDescription }
                image={ CodeReviewIcon }
                title="Code Audits"
                hrefLink="#services-code-audits"
                mediaHeight={ cardMediaHeight }
            />
        </Grid>
    );
}
