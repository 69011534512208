import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import PhoneIcon from "@mui/icons-material/Phone";
import React from "react";
import Typography from "@mui/material/Typography";

type ContactCardProps = {
    title: string;
    email: string;
    phoneNumber: string;
}

/**
 * Card for displaying contact information.
 */
function ContactCard(props: ContactCardProps): JSX.Element {
    const { title, email, phoneNumber } = props;

    return (
        <Box>
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    align="center"
                    color="#1A73E8"
                    fontWeight="bold"
                >
                    { title }
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    align="center"
                    textAlign="center"
                >
                    <div>
                        <EmailIcon sx={ { pr: 2 } } />
                        <span>{ email }</span>
                    </div>
                    <div>
                        <PhoneIcon sx={ { pr: 2 } } />
                        <span>{ phoneNumber }</span>
                    </div>

                </Typography>
            </CardContent>
        </Box>
    );
}

/**
 * Displays the Contact Information section of the page.
 */
export default function ContactUsSection() {
    return (
        <Container
            id="about-us"
            sx={ {
                pb: 5,
                pt: 5
            } }
        >
            <Container
                sx={ {
                    justifyContent: "center"
                } }
            >
                <Typography
                    variant="h1"
                    fontWeight="bold"
                    textAlign="center"
                >
                    Contact Us
                </Typography>
            </Container>

            <Grid
                id="contact-us"
                container
                spacing={ 2 }
                direction="row"
                sx={ {
                    pt: 5
                } }
                justifyContent="center"
            >
                <Grid
                    id="contact-us-services-information"
                    item
                    xs={ 12 }
                    md={ 6 }
                >
                    <ContactCard
                        title="Services Information"
                        email="services@lightsecondengineering.com"
                        phoneNumber="(717) 746-8255"
                    />
                </Grid>


                <Grid
                    id="contact-us-career-opportunities"
                    item
                    xs={ 12 }
                    md={ 6 }
                >
                    <ContactCard
                        title="Career Opportunities"
                        email="careers@lightsecondengineering.com"
                        phoneNumber="(717) 746-8255"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
