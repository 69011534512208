import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import React from "react";
import Typography from "@mui/material/Typography";

export type Props = {
    title: string;
    body: string;
}

export default function DescriptionCard(props: Props) {
    const { title, body } = props;

    return (
        <Box>
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    align="center"
                    color="#1A73E8"
                    fontWeight="bold"
                >
                    { title }
                </Typography>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    align="center"
                >
                    { body }
                </Typography>
            </CardContent>
        </Box>
    );
}
