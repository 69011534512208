import { styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import React from "react";
import Typography from "@mui/material/Typography";

import AboutUs from "./sections/AboutUs";
import ContactUsSection from "./sections/ContacUsSection";
import FooterBar from "./components/FooterBar";
import MuiTheme from "./MuiTheme";
import NavigationBar, { NavItem } from "./components/NavigationBar";
import ServiceCardRow from "./components/ServiceCardRow";
import ServicesSection from "./sections/ServicesSection";

const JumbotronDisplay = styled(Box)(() => ({
    backgroundColor: "rgba(26, 115, 232, 0.4)",
    backgroundSize: "cover", // TODO `backgroundSize` & `backgroundPosition`
    minHeight: "65vh",
    placeItems: "center",
    display: "grid"
}));

const pageContainerStyles: React.CSSProperties = {
    position: "relative",
    minHeight: "100vh"
};

const navigationLinks: NavItem[] = [
    {
        name: "About",
        hrefLink: "#about-us"
    },
    {
        name: "Services",
        hrefLink: "#services"
    },
    {
        name: "Careers",
        hrefLink: "#contact-us-career-opportunities"
    },
    {
        name: "Contact",
        hrefLink: "#contact-us"
    }
];

/**
 * Used to draw a line across the encapsulating container to symbolize a division in sections
 */
function SectionDivider() {
    return (
        <hr style={ {
            border: "none",
            height: "1px",
            backgroundColor: "lightblue"
        } }
        />
    );
}

function App(): JSX.Element {
    // TODO Use MUI Stepper for GitLab-like pipeline
    // TODO Use MUI Transitions to show content on page when scrolling

    // TODO look into using an animation library, such as Framer Motion

    return (
        <ThemeProvider theme={ MuiTheme }>
            <div style={ pageContainerStyles }>
                <NavigationBar navItems={ navigationLinks } />

                <JumbotronDisplay>
                    <Container>
                        <Box justifyContent="center">
                            <Typography
                                variant="h2"
                                fontWeight="bold"
                                textAlign="center"
                            >
                                Light<span style={ { color: "#EE3302" } }>Second</span> Engineering
                            </Typography>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={ {
                                    fontStyle: "italic"
                                } }
                            >
                                Solution. Done. Right.
                            </Typography>
                        </Box>
                    </Container>
                </JumbotronDisplay>

                <Box
                    sx={ {
                        display: "flex",
                        flexWrap: "wrap",
                        // p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: -18,
                        mb: 4,
                        pt: 0
                    } }
                >
                    <ServiceCardRow
                        showDescriptions
                        cardMediaHeight={ 140 }
                    />
                </Box>
                
                <br />
                <br />
                <br />

                <Card
                    sx={ {
                        flexGrow: 1,
                        ml: 3,
                        mr: 3,
                        pb: 1
                    } }
                >
                    <AboutUs />
                    
                    { /* TODO rather than have each section be responsible for introducing the padding, have
                            the SectionDivider be responsible for the padding using MUI style theme */ }
                    <SectionDivider />

                    <ServicesSection />

                    <SectionDivider />

                    <ContactUsSection />

                </Card>



                <br />
                <br />
                <br />

                <FooterBar />
            </div>
        </ThemeProvider>

    );
}

export default App;
