import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";

import DescriptionCard from "../components/DescriptionCard";
import TeamworkImage from "../assets/images/teamwork-hands-cropped.jpg";

export default function AboutUs(): JSX.Element {
    return (
        <Container
            id="about-us"
            sx={ {
                pb: 5
            } }
        >
            <Container
                sx={ {
                    justifyContent: "center",
                    pb: 3
                } }
            >
                <Typography
                    variant="h1"
                    fontWeight="bold"
                    textAlign="center"
                >
                    About Us
                </Typography>
            </Container>

            { /* ---- Mission Statement ---- */ }
            <Typography
                variant="body1"
                color="text.primary"
                align="center"
                fontWeight="bold"
            >
                At LightSecond Engineering, our mission is twofold: to cultivate a team of senior
                software engineering talent, offering exceptional benefits and opportunities for professional
                growth, while also pursuing complex and intriguing federal contract opportunities.
                We aspire to become trusted advisors and solutions providers for our customers by excelling
                in software development and delivering sustainable cloud implementations.
                Our commitment lies in crafting custom software and cloud solutions that are not only extensible,
                secure, and easily maintained but also tailored to meet the unique needs and challenges of our
                clients, fostering enduring partnerships built on innovation, integrity, and excellence.
            </Typography>

            <br />

            <Grid container spacing={ 3 }>

                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    sx={ { textAlign: "center" } }
                    justifyContent="center"
                >
                    <DescriptionCard
                        title="Overview"
                        body="We are a software engineering company specialized in providing secure
                        software solutions and IT automation. Whether you are building capabilities
                        from scratch or modernizing legacy applications, we can help. We have
                        adopted a strong culture around continuous integration and continuous
                        delivery (CI/CD) - enhancing team efficiency, software quality, and reliability."
                    />
                </Grid>
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    sx={ { textAlign: "center" } }
                    justifyContent="center"
                >
                    <DescriptionCard
                        title="Minority-Owned Small Business"
                        body="LightSecond Engineering is a small business providing tailored solutions,
                        specialized to the customer requirements. We work dillegently to to build the
                        right solution on schedule and within budget. We encourage frequent collaboration
                        and the exchange of different ideas and experiences. We value diversity and have
                        found that it increases both quality and productivity. Our headquarters are in
                        Pennsylvania and we support customers anywhere across the U.S."
                    />
                </Grid>
            </Grid>


            <Grid
                id="about-us-our-team"
                container
                spacing={ 2 }
                direction="row"
                sx={ {
                    pt: 5
                } }
            >
                <Container
                    sx={ {
                        justifyContent: "center",
                        pb: 3
                    } }
                >
                    <Typography
                        variant="h2"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Our Team
                    </Typography>
                </Container>

                <Grid
                    item
                    xs={ 12 }
                    md={ 5 }
                >
                    { /* TODO when xs, change height to 100% */ }
                    <img
                        src={ TeamworkImage }
                        alt="Team"
                        style={ {
                            height: "80%",
                            maxWidth: "100%",
                            borderRadius: "16px",
                            boxShadow: "0px 30px 40px -25px rgba(0, 0, 0, 1)"
                        } }
                    />
                </Grid>
                <Grid
                    item
                    xs={ 12 }
                    md={ 7 }
                >
                    <Grid
                        container
                        spacing={ 2 }
                        direction="row"
                    >
                        <Grid
                            item
                            xs={ 12 }
                            md={ 6 }
                        >
                            <DescriptionCard
                                title="Overview"
                                body="Our team is composed of industry-leading software engineers,
                                adept in a wide variety of technologies and languages. Our team members have
                                extensive backgrounds in software engineering and advanced degrees in computer science.
                                Our engineers have substantial experience developing solutions for U.S. government
                                agencies and industry-leading tech companies"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            md={ 6 }
                        >
                            <DescriptionCard
                                title="Growth"
                                body="We are strong proponents of continuous learning, allowing us to provide
                                solutions that proficiently leverage the latest technologies and techniques.
                                We ensure our engineers have access to the training and resources they require,
                                in order to advance their careers and the services they provide to our customers."
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            md={ 6 }
                        >
                            <DescriptionCard
                                title="Family"
                                body="We are not just a company; we are a team and a family. We value everyone's hard
                                work and dedication to providing the best quality services for our customers. We
                                encourage collaboration and we respect differences in opinions, perspectives, and experiences."
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            md={ 6 }
                        >
                            <Box>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h4"
                                        component="div"
                                        align="center"
                                        color="#1A73E8"
                                        fontWeight="bold"
                                    >
                                        Careers
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        align="center"
                                    >
                                        We want elite engineers that are passionate about what they do and who
                                        they work with. Join our family! If you are looking to advance your
                                        career in a challenging and rewarding environment,
                                        <a href="#careers" style={ { textDecoration: "none", color: "#1A73E8" } }>
                                            <span> contact us </span>
                                        </a>
                                        to learn more about why our family is
                                        the best choice for you.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
