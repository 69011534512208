import React, { ReactElement } from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import logo from "../assets/images/lse-full-logo-916x278.png";

export type NavItem = {
    
    /**
     * The display name for the link.
     */
    name: string;

    /**
     * The href to use when the card is selected. For example, `#services-code-audits`
     * would cause the component to scroll to element with the HTML ID `services-code-audits`
     */
    hrefLink: string;
}

export type Props = {
    
    /**
     * The navigation links that will be displayed.
     */
    navItems: NavItem[];
}

export default function NavigationBar(props: Props): ReactElement {
    const { navItems } = props;

    // TODO Currently, the links will not show on small devices, such as phones.
    //      If a small device is displaying the Navigation Bar, allow the user to
    //      select a dropdown menu, where they can select the nav items
    return (
        <Box sx={ { flexGrow: 1, display: "flex" } }>
            <AppBar position="static" sx={ { backgroundColor: "white" } }>
                <Toolbar sx={ { justifyContent: "space-between" } }>
                    <img src={ logo } alt="logo" style={ { height: "50px" } } />

                    <Box sx={ { display: { xs: "none", sm: "block" } } }>
                        {navItems.map((item: NavItem) => (
                            <Button
                                key={ item.name }
                                href={ item.hrefLink }
                                sx={ { color: "#fff" } }
                            >
                                <span style={ { color: "black" } }>{ item.name}</span>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
