import React from "react";

const footerStyles: React.CSSProperties = {
    textAlign: "center",
    bottom: "10px",
    position: "absolute",
    width: "100%"
};

export default function FooterBar(): JSX.Element {
    const nowDate: Date = new Date();
    const copyRightyear: number = nowDate.getFullYear();
    
    return (
        <footer style={ footerStyles }>
            Copyright &copy; LightSecond, LLC { copyRightyear }
        </footer>
    );
}
