import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";

import ServiceCardRow from "../components/ServiceCardRow";
import DescriptionCard from "../components/DescriptionCard";

// TODO should split the subsection components into their own file to avoid the clutter.

function DevSecOpsSubSection(): JSX.Element {
    return (
        <Grid
            id="services-dev-sec-ops"
            container
            spacing={ 2 }
            direction="row"
            sx={ {
                pt: 5
            } }
            justifyContent="center"
        >
            <Container
                sx={ {
                    justifyContent: "center"
                } }
            >
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                >
                    DevSecOps
                </Typography>
            </Container>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 4 }
                >
                    <DescriptionCard
                        title="Why DevSecOps?"
                        body="Technologies rapidly change and security vulnerabilities are continuously evolving.
                        How do you maintain a secure application, while still allowing for consistent and
                        productive deployments?
                        The answer is DevSecOps."
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 6 }
                >
                    <Box>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                align="center"
                                color="#1A73E8"
                                fontWeight="bold"
                            >
                                How We Help
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                align="center"
                            >
                                <div>
                                    Rather than waiting until the end of the development lifecycle to implement
                                    security features, we consider security at every stage of the development
                                    lifecycle, including design, development, testing, deployment, and post deployment.
                                </div>
                                <br />
                                <div>
                                    We utilize a wide variety of technologies, methodologies, and techniques to ensure
                                    all aspects of the development life cycle are seamless and secure. The methods
                                    used depend on the needs of our customers, but some common methods involve
                                    containerization,
                                    <a href="#services-ci-cd-pipelines" style={ { textDecoration: "none", color: "#1A73E8" } }>
                                        <span> automation</span>
                                    </a>
                                    , security scans, and
                                    <a href="#services-code-audits" style={ { textDecoration: "none", color: "#1A73E8" } }>
                                        <span> code audits</span>
                                    </a>
                                    .
                                </div>
                                { /* TODO Have icons of different technologies, such as OpenShift, Docker, etc.
                                    Docker, Podman, Kubernetees, & OpenShift
                                */ }
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>

        </Grid>
    );
}

function PipelineSubSection(): JSX.Element {
    return (
        <Grid
            id="services-ci-cd-pipelines"
            container
            spacing={ 2 }
            direction="row"
            sx={ {
                pt: 5
            } }
            justifyContent="center"
        >
            <Container
                sx={ {
                    justifyContent: "center"
                } }
            >
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                >
                    CI/CD Pipelines
                </Typography>
            </Container>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 4 }
                >
                    <DescriptionCard
                        title="Why Pipelines?"
                        body="Fast, secure, and reliable deployments are critical for the success of applications
                        and organizations. Unfortunately, these three things are typically treated as mutually
                        exclusive, where only one can be achieved but not all three. However, if done properly,
                        CI/CD Pipelines can allow each of these to be achieved together, continuously, and repeatably."
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 6 }
                >
                    <Box>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                align="center"
                                color="#1A73E8"
                                fontWeight="bold"
                            >
                                How We Help
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                align="center"
                            >
                                <div>
                                    Rather than wasting resources on manually performing deployments, security
                                    vulnerability testing, and other developer workflows, we can help you
                                    automate your environment and workflows with CI/CD pipelines.

                                    We take complex applications and automate the building, testing, security
                                    scanning, and deployment of the application.
                                </div>
                                { /* GitLab, Jenkins, GitHub, BitBucket  */ }
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 6 }
                >
                    <Box>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                align="center"
                                color="#1A73E8"
                                fontWeight="bold"
                            >
                                How We Do It
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                align="center"
                            >
                                <div>
                                    Every project is unique; however, there are techniques
                                    and practices that have proven track records of success. We
                                    work with customers to carefully and deliberately adopt specific
                                    techniques that provide the biggest gains in productivity and
                                    security. Examples include: [a] CI pipelines for continuous build/test/integration
                                    of new features, [b] CI templates for reuse, and [c] container deployments
                                    for scaling and security.
                                </div>
                                { /* GitLab, Jenkins, GitHub, BitBucket  */ }
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>


        </Grid>
    );
}

function SoftwareDevelopmentSubSection(): JSX.Element {
    return (
        <Grid
            id="services-software-development"
            container
            spacing={ 2 }
            direction="row"
            sx={ {
                pt: 5
            } }
            justifyContent="center"
        >
            <Container
                sx={ {
                    justifyContent: "center"
                } }
            >
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                >
                    Software Development
                </Typography>
            </Container>

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 6 }
                >
                    <Box>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                align="center"
                                color="#1A73E8"
                                fontWeight="bold"
                            >
                                What We Do
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                align="center"
                            >
                                <div>
                                    We take pride in developing software that adheres to best-practices, is easy to maintain,
                                    easy to use, and secure.
                                </div>
                                <br />
                                <div>
                                    We have expertise in many common languages, including web/frontend (TypeScript, JavaScript, React, Vue) and
                                    backend (Python, Go, C/C++, Rust, Java). Additionally, we have experience with various
                                    database technologies - including SQL and NoSQL databases (PostgreSQL, Oracle,
                                    Mongo, Elasticsearch, Accumulo).
                                </div>
                                <br />
                                <div>
                                    Our engineers routinely work and deploy within container environments and are well-versed
                                    in using Kubernetes, OpenShift, Docker, and Podman. We encourage CNCF (and similar) certifications
                                    such as CKA, CKAD, and Docker.
                                </div>
                                { /* Java, Rust, React, Vue  */ }
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

function CodeAuditSubSection(): JSX.Element {
    return (
        <Grid
            id="services-code-audits"
            container
            spacing={ 2 }
            direction="row"
            sx={ {
                pt: 5
            } }
            justifyContent="center"
        >
            <Container
                sx={ {
                    justifyContent: "center"
                } }
            >
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                >
                    Code Audits
                </Typography>
            </Container>

            { /* TODO add portion about performing penetration testing */ }

            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    lg={ 4 }
                >
                    <DescriptionCard
                        title="How we Help"
                        body="Every company has their specific niche in the software industry. Some companies may
                        specialize in web development and others may focus on database development. We want to
                        allow you to focus on your specialty and leave it up to us to ensure that your code
                        meets security and development standards."
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function ServicesSection(): JSX.Element {
    return (
        <Container
            id="services"
            sx={ {
                pb: 5,
                pt: 5
            } }
        >
            <Container sx={ { justifyContent: "center", pb: 5 } }>
                <Typography
                    variant="h1"
                    fontWeight="bold"
                    textAlign="center"
                >
                    Services
                </Typography>
            </Container>


            <Box
                sx={ {
                    display: "flex",
                    flexWrap: "wrap"
                } }
            >
                <ServiceCardRow
                    showDescriptions={ false }
                    cardMediaHeight={ 60 }
                />
            </Box>

            <DevSecOpsSubSection />

            <PipelineSubSection />

            <SoftwareDevelopmentSubSection />

            <CodeAuditSubSection />

        </Container>
    );
}
