import { SxProps } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";

export type Props = {

    /**
     * The path to the image to display
     */
    image: string;

    /**
     * The title to display below the image and above the description.
     */
    title: string;

    /**
     * The sub-text for the card to display underneath the title.
     * If none is provided, then the section will not be displayed
     */
    description: string;

    /**
     * The href to use when the card is selected. For example, `#services-code-audits`
     * would cause the component to scroll to element with the HTML ID `services-code-audits`
     */
    hrefLink: string;

    /**
     * The height of the card. If not provided, it will default to `140`
     */
    mediaHeight: number;
}

export default function ServiceCard(props: Props): JSX.Element {
    const DEFAULT_CARD_ELEVATION: number = 5;
    const RAISED_CARD_ELEVATION: number = 20;

    const [cardElevation, setCardElevation] = useState<number>(DEFAULT_CARD_ELEVATION);

    const onCardMouseHover = useCallback(() => {
        setCardElevation(RAISED_CARD_ELEVATION);
    }, []);

    const onCardMouseOut = useCallback(() => {
        setCardElevation(DEFAULT_CARD_ELEVATION);
    }, []);

    const { description, mediaHeight, hrefLink, image, title } = props;

    // Creating a separate object for CardMedia `sx` because the component will NOT
    //      re-render when React Props are provided to an individual field in the `sx`.
    //      It will only re-render when you provide it a new SxProps object.
    // TODO memoize assignment to be reliant on change in `props.cardHeight`
    const cardMediaSx: SxProps = {
        backgroundSize: "contain",
        height: mediaHeight
    };

    // TODO re-write to utilize MUI theme provider
    let titleSize: string = "1.5rem";
    if (mediaHeight <= 60) {
        titleSize = "1rem";
    }

    return (
        <Grid item md={ 3 } xs={ 12 }>
            <Card
                elevation={ cardElevation }
                onMouseOver={ onCardMouseHover }
                onMouseOut={ onCardMouseOut }
                sx={ {
                    borderRadius: "16px"
                } }
            >
                <CardActionArea href={ hrefLink }>
                    <CardMedia
                        sx={ cardMediaSx }
                        image={ image }
                    />
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            align="center"
                            fontSize={ titleSize }
                        >
                            { title }
                        </Typography>

                        { /* Only show the Description portion if there is a description */ }
                        { (description !== "") && (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                            >
                                { description }
                            </Typography>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}
