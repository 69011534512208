import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

// These are the default MUI breakpoints based on the sizes of the screen
const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
    }
};

const theme = createTheme({
    breakpoints,
    palette: {
        // Look at MUI Default Themes for the default themes (https://mui.com/material-ui/customization/default-theme/)
        primary: {
            main: "#1976d2"
        },
        info: {
            main: "#1A73E8"
        }
    },
    status: {
        danger: orange[500]
    },
    typography: {
        h1: {
            fontSize: "6rem",
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: "3rem"
            }
        },
        h2: {
            fontSize: "3rem",
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: "2rem"
            }
        },
        h4: {
            fontSize: "2.125rem",
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: "1.5rem"
            }
        }
    }
});

export default theme;
